import React, {
  useState,
  useReducer,
  useRef,
} from 'react'
import Styled from 'styled-components'
import { Auth } from 'aws-amplify'
import ReactIsCapsLockActive from '@matsun/reactiscapslockactive'

import Button from '../../Common/button'
import PasswordValidation from './PasswordValidation'
import PasswordInput from './ShowHidePassword'
import {
  validateUser,
  normalizeErrorMessage,
  validateUserNameAndPasswords,
  getRandomLetters,
  validateUpbUser,
  validateUserAccountValidation,
} from '../../services/auth'
import Title from '../../Common/Title'
import { useFocus } from '../../Hooks/useFocus'
import { buildTestID } from '../../Utils/utils'
import { trackRegistration } from '../../Global/Analytics'
import useWindowSize from '../../Hooks/useWindowSize'
import NeedHelpModal from './NeedHelpModal'
import {
  OverlayTrigger,
  Button as OldButton,
  Tooltip,
} from 'react-bootstrap'
import VectorIcon from '../../assets/vector-icon'
import LockoutDialog from './UpbMismatchCard'

export const StyledSignUp = Styled.div`
  font-size: 20px;

  .sign-up-label {
    font-size: 19px;
    font-weight: bold;
    font-family: Lato;
    font-weight: 600;
    line-height: 22px;
  }
  
  .verified-value{
  font-family: Lato;
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  }
  .no-err-input{
    width:100%;
  }
  .err-input .signup_input{
    border-color:red;
  }
  .upb-err{
    border-color:red !important;
  }
   @media (min-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    .error-placement{
      display:flex;
      align-items:center;
      column-gap:5px
    }
  }
  .error-message {
    color: ${({ theme }) => theme.colors.error};
  }

  .upb_input_container{
    display:flex;
    align-items:center;
    border:1px solid #ccc;
    border-radius: 4px;
    width:85%;
    padding:0 8px;
    .dollar-sign{
      margin-right: 1px solid black;
      padding:10px;
      border-right:1px solid #ccc;
    }
    input {
        border:none;
        outline:none;
        margin-bottom:0;
      }
    @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
      width:100%;
    }
    }

  .submit-button-container {
    display: block;
  }

  .caps-lock-text {
    color: green;
    font-weight: bold;
  }

  .error_button_group {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .mandatory{
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    display:flex;
    align-items:center;
    column-gap:5px;
}
  .mobile_err{
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom:7px;
  }
  .signup_input{
    padding:8px;
    width:95%;
  }
  .signup_label{
    margin-bottom:0px;
  }
  svg{
  vertical-align:top
  }
`
export const RegistrationButton = Styled.button`
  background-color: #9BAAC0;
  color: white;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: center;
  border-radius:10px;
  padding:10px;
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    width:100%;
  }
  &:enabled{
    background-color: #475468;
  }  
  `;

  const HelpButton = Styled.button`
    color:#475468;
    border:1px solid #475468;
    padding:10px;
    border-radius:10px; 
    background:white;
  `;

const SignUp = ({ setAuthState, setAuthData }) => {
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isVerified, setIsVerified] = useState(false)
  const [error, setError] = useState('')
  const [errorCount, setErrorCount] = useState(0)
  const [isInitialScreen, setIsInitialScreen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [randomLetters, setRandomLetters] = useState('')
  const [validationLoading,setValidationLoading] = useState(false)
  const [givenNameErrorMessage, setGivenNameErrorMessage] = useState('')
  const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState('')
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState('')
  const [lastFourErrorMessage, setLastFourErrorMessage] = useState('')
  const [loanNumberErrorMessage, setLoanNumberErrorMessage] = useState('')
  const [upbAmountErrorMessage,setUpbAmountErrorMessage] = useState('')
  const [confirmEmailErrorMessage] = useState('')
  const {isXSmall}=useWindowSize()
  const [helpModal,setHelpModal]=useState(false)
  const [showupbAmountField,setShowUpbAmountField] = useState(false)
  const [isUpbVerified,setIsUpbVerified] = useState(false)
  const [upbMismatch, setUpbMismatch] = useState(false)
  const errorRef = useRef(null)
  const { focus } = useFocus(errorRef, !!error)

  const registrationParameters = [
    'given_name',
    'family_name',
    'email',
    'custom:person_id',
  ]

  const errorCountUntilContactMessage = 3

  // const [state, dispatch] = useReducer((state, { name, value }) => {
  //   return { ...state, [name]: value }
  // })


  const initialState = {};
  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE':
        return { ...state, [action.name]: action.value };
      case 'RESET':
        return {};
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleDispatch =
    (name, value = null) =>
      (e) => {
        if (name==='loan_number'){
          let trimmedValue = value || e.target.value
          if (trimmedValue){
            trimmedValue=trimmedValue.trim()
          }
          dispatch({type: 'UPDATE', name, value: trimmedValue })
        }
        else{
        dispatch({type: 'UPDATE', name, value: value || e.target.value })
        }
        // dispatch({ name, value: value || e.target.value })
        if (name === 'email' || name === 'confirm_email')
          setIsEmailValid(true)

      }


      const handleVerifyAccountValidation = (e) => {
        e.preventDefault()
        try {
          const error = validateInput(state)
          if (error) return
        } catch ({ message }) {
          handleError(message)
          return
        }
    
        validateUserAccountValidation(state)
          .then((result) => {
            const { personId } = result
    
            handleDispatch('custom:person_id', personId + '')(null)
            setIsVerified(true)
            setIsInitialScreen(false)
            setError('')
            trackRegistration.StepTwo()
          })
          .catch(({ message }) => {
            handleError(message)
          })
      }


  const handleVerifyRegistrationStep = (e) => {
    e.preventDefault()
    try {
      const error = validateInput(state)
      if (error) return
    } catch ({ message }) {
      handleError(message)
      return
    }
    setValidationLoading(true)
    validateUser(state)
      .then((result) => {
        const { person_id,session_id,check_upb_amount } = result

        handleDispatch('custom:person_id', person_id + '')(null)
        handleDispatch('session_id', session_id + '')(null)
        setIsVerified(true)
        setShowUpbAmountField(check_upb_amount)
        setError('')
        trackRegistration.StepTwo()
      })
      .catch(({ message }) => {
        handleError(message)
      }).finally(() => {
        setValidationLoading(false)
      })
  }

  const handleUpbVerify = (e) => {
    e.preventDefault()
    try{
      const error = validateUpbInput(state)
      if (error) return
    }
    catch({message}) {
      handleError(message)
      return
    }
    setValidationLoading(true)
    setUpbAmountErrorMessage('')
    validateUpbUser({session_id:state.session_id,upb_amount:state.upb_amount})
      .then((result) => {
      const { is_valid,attempts_left } = result
      if (is_valid){
        setIsUpbVerified(true)
        setIsInitialScreen(false)
        setShowUpbAmountField(false)
        setError('')
      }
      else if (attempts_left > 0){
        handleError(`Your entry does not match our records. Please check your entry and try again.`)
      }
      else{
        setError('')
        setUpbMismatch(true)
      }
      trackRegistration.StepThree()
    })
    .catch(({ message }) => {
      handleError(message)
    }).finally(() => {
      setValidationLoading(false)
    })
  }
  
  const handleError = (message) => {
    if (message.includes('The values you entered for email do not match. Please enter matching values for Email and Confirm Email fields.'))
      setIsEmailValid(false)

    setRandomLetters(getRandomLetters(5))
    const showContactMessage =
      errorCount + 1 >= errorCountUntilContactMessage
    setError(
      <div role="alert">
        {message}
        <span>
          {showContactMessage ? (
            <div role="alert">
              <br />
              If you are experiencing difficulty creating an account on this site, please contact us at{' '}<a className="subtitle-2 bold" href={`tel:+18666540020`}>(866) 654-0020</a>.{' '}Our representatives are available Monday-Thursday, 8am-7pm, Friday 8am-5pm Eastern time to assist you.
            </div>
          ) : (
            <span />
          )}
        </span>
      </div>,
    )
    setErrorCount(errorCount + 1)
    focus()
  }
  function renderOverlayTrigger(message){
    return (
      <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
          <Tooltip {...props}>
              {message}
              </Tooltip>
            )}
          >
          <span style={{marginLeft:'9px'}}><VectorIcon /></span>
      </OverlayTrigger>
    )
  }
  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    setError('')

    let submittedState = {
      ...state,
      email: state.email.toLowerCase(),
      confirmEmail: state.confirm_email.toLowerCase(),
    }

    let { email, confirmEmail } = submittedState

    const isUsernameAndPasswordValid = validateUserNameAndPasswords(
      email,
      confirmEmail,
      password,
      confirmPassword,
      (error) => {
        handleError(error)
        setLoading(false)
      },
    )
    if (!isUsernameAndPasswordValid) return
    Auth.signUp({
      username: email,
      password,
      attributes: registrationParameters.reduce((a, b) => {
        a[b] = submittedState[b]
        return a
      }, {}),
      validationData: Object.keys(submittedState)
        .filter((key) => !registrationParameters.includes(key))
        .reduce((a, b) => {
          a[b] = submittedState[b]
          return a
        }, []),
    })
      .then((data) => {
        if (!data.userConfirmed) {
          setAuthState('confirmSignUp')
          setAuthData({ username: email })
          trackRegistration.StepFour()
        }
      })
      .catch((err) => {
        handleError(normalizeErrorMessage(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getField = (
    label,
    isRequired,
    name,
    showValueIfVerified,
    placeHolderLabel,
    needsStar,
    length = 50,
  ) => {
    return (
      <div className="Form__formField___38Ikl signup_label">
        <label className='sign-up-label' htmlFor={name}>{label}</label>
        <div className="error-placement">
        {showValueIfVerified ? (
          isVerified ? (
            <div className='verified-value'>
              {state[name]}
              <i className="fas fa-check"></i>
            </div>
          ) : (
            <>
            {name === 'given_name' &&
            <>
             <div className={givenNameErrorMessage?'err-input no-err-input':'no-err-input'}>
             {getInput(label, name, length)}
              </div>
              <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: givenNameErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
                {!isXSmall? renderOverlayTrigger(givenNameErrorMessage): <div className="mobile_err"><VectorIcon />&nbsp; {givenNameErrorMessage}</div>}
              </div>
            </>
            }
            {name === 'family_name' &&
            <>
            <div className={familyNameErrorMessage?'err-input no-err-input':'no-err-input'}>
             {getInput(label, name, length)}
              </div>  
            <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: familyNameErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
                {!isXSmall? renderOverlayTrigger(familyNameErrorMessage): <div className="mobile_err"><VectorIcon /> {familyNameErrorMessage}</div>}
              </div>
            </>
            }
            {name === 'zip' &&
            <>
             <div className={zipCodeErrorMessage?'err-input no-err-input':'no-err-input'}>
                {getInput(label, name, length)}
              </div>            
              <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: zipCodeErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
                {!isXSmall? renderOverlayTrigger(zipCodeErrorMessage): <div className="mobile_err"><VectorIcon /> {zipCodeErrorMessage}</div>}
              </div>
            </>
            }
            {name === 'last4ssn' &&
            <>
              <div className={lastFourErrorMessage?'err-input no-err-input':'no-err-input'}>
                {getInput(label, name, length)}
              </div>
              <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: lastFourErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
                {!isXSmall? renderOverlayTrigger(lastFourErrorMessage): <div className="mobile_err"><VectorIcon /> {lastFourErrorMessage}</div>}
              </div>
            </>
            }
            {name === 'loan_number' &&
            <>
              <div className={loanNumberErrorMessage?'err-input no-err-input':'no-err-input'}>
                {getInput(label, name, length)}
              </div>
              <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: loanNumberErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
                {!isXSmall? renderOverlayTrigger(loanNumberErrorMessage): <div className="mobile_err"><VectorIcon /> {loanNumberErrorMessage}</div>}
              </div>
            </>
            }
          </>
          )
        ) : (
          <>
            {
            name === 'upb_amount'?
            <>
            <div className={`upb_input_container ${upbAmountErrorMessage?'upb-err':''} `}>
              <span className="dollar-sign">$</span>
              {getInput(placeHolderLabel ?placeHolderLabel: label, name, length)}
            </div>
            <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: upbAmountErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
                {!isXSmall? renderOverlayTrigger(upbAmountErrorMessage): <div className="mobile_err"><VectorIcon /> {upbAmountErrorMessage}</div>}
              </div>
            </>
            :
            getInput(placeHolderLabel ?placeHolderLabel: label, name, length)
            }
          </>
          
        )}
       
        {name === 'confirm_email' &&
          <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: confirmEmailErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
            {!isXSmall? renderOverlayTrigger(confirmEmailErrorMessage): <div className="mobile_err"><VectorIcon /> {confirmEmailErrorMessage}</div>}
          </div>
        }
        </div>
        {/* {name === 'upb_amount' &&
          <div role="alert" aria-live="polite" className="error-message" style={{ fontWeight: 'bold', display: upbAmountErrorMessage !== '' ? "block" : "none" }} data-testid={buildTestID(name, '/home', 'sign_up')}>
            * {upbAmountErrorMessage}
          </div>
        } */}
      </div>
    )
  }

  const getInput = (label, name, length) => {
    return <input
      placeholder={label}
      type={name}
      name={name}
      className="Input__input___3e_bf signup_input"
      onChange={handleDispatch(name)}
      value={state[name]}
      maxLength={length}
      id={name}
      aria-required="true"
      data-testid={buildTestID(name, '/home', 'sign_up')}
      onPaste={name === 'confirm_email' ? (e) => e.preventDefault() : null}
    />
  }

  const validateInput = (input) => {
    let error = false
    if (!input || !input.given_name) {
      error = true
      setGivenNameErrorMessage('First name cannot be empty')
    }
    else if (!checkForOnlyLetters(!input || input.given_name)) {
      error = true
      setGivenNameErrorMessage('First name can only contain letters')
    }
    else {
      setGivenNameErrorMessage('')
    }

    if (!input || !input.family_name) {
      error = true
      setFamilyNameErrorMessage('Last name cannot be empty')
    }
    else if (!input || !checkForOnlyLetters(input.family_name)) {
      error = true
      setFamilyNameErrorMessage('Last name can only contain letters')
    }
    else {
      setFamilyNameErrorMessage('')
    }

    if (!input || !input.zip) {
      error = true
      setZipCodeErrorMessage('Zip code of property address cannot be empty')
    } else {
      setZipCodeErrorMessage('')
    }

    if (!input || !input.last4ssn) {
      error = true
      setLastFourErrorMessage('Social security number cannot be empty')
    }
    else if (!input || isNaN(input.last4ssn)) {
      error = true
      setLastFourErrorMessage('Social security must be a number')
    }
    else if (!input || input.last4ssn.length !== 4) {
      error = true
      setLastFourErrorMessage('Last 4 of social security number must contain 4 numbers')
    } else {
      setLastFourErrorMessage('')
    }

    if (!input || !input.loan_number) {
      error = true
      setLoanNumberErrorMessage('Loan number cannot be empty')
    }
    else if (!input || isNaN(input.loan_number)) {
      error = true
      setLoanNumberErrorMessage('Loan number must be a number')
    }
    else {
      setLoanNumberErrorMessage('')
    }

    return error
  }
  const handleHelpModal=()=>{
    setHelpModal(true)
  }
  const validateUpbInput = (input) => {
    let error = false
    setError('')
    if (!input || !input.upb_amount) {
      error = true
      setUpbAmountErrorMessage('Balance amount cannot be empty')
    }
    else if (!input || isNaN(input.upb_amount)) {
      error = true
      setUpbAmountErrorMessage('Balance amount must be a number')
    }
    else {
      setUpbAmountErrorMessage('')
    }
    return error
  }
  const handleRegistrationFunction=(e)=>{
    process.env.REACT_APP_IS_NEW_EXTERNAL_INVOKE_URL==='true'?handleVerifyRegistrationStep(e):handleVerifyAccountValidation(e)
  }
  const handleClose=()=>{
    if (upbMismatch){
    setUpbMismatch(false)
    dispatch({type:'RESET'})
    setIsVerified(false)
    setShowUpbAmountField(false)
    setIsInitialScreen(true)
    }
    else{
      setShowUpbAmountField(false)
      setIsVerified(false)
    }
  }
  const checkForOnlyLetters = (input) =>
    !!/^[a-zA-Z .,'-]*$/g.test(input)
  /*eslint-disable */
  /*eslint-enable */
  return (
    <>
      <Title>Registration</Title>
      <StyledSignUp>
        <form onSubmit={handleSubmit}>
          <p className="mandatory">All fields below are mandatory
           {isXSmall && <HelpButton
                  onClick={() => {
                    setHelpModal(true)
                  }}
                  type='button'
                >
                  Need Help?  
                </HelpButton>}
           {helpModal && <NeedHelpModal setHelpModal={setHelpModal} /> }
          </p>
          <div className="Section__sectionBody___ihqqd">
            {getField('First Name (as seen on borrower statement or loan agreement)', true, 'given_name', true,'')}
            {getField('Last Name (as seen on borrower statement or loan agreement)', true, 'family_name', true,'')}
            {getField(
              'Zip Code of Property Address',
              true,
              'zip',
              true,
              '',
              true,
              5,
            )}
            {getField(
              'Social Security Number (last 4 digits)',
              true,
              'last4ssn',
              true,
              '',
              true,
              4,
            )}
            {getField(
              'Loan Number (as seen on borrower statement or loan agreement)',
              true,
              'loan_number',
              true,
              '',
            )}
            {!showupbAmountField && isUpbVerified && getField(
              'Current Total Loan Balance Amount',
              true,
              'upb_amount',
              isUpbVerified,
              '',
            )}
            {<LockoutDialog show={showupbAmountField} onHide={handleClose} getField={getField} isUpbVerified={isUpbVerified} error={error} errorRef={errorRef} handleUpbVerify={handleUpbVerify} randomLetters={randomLetters} upbMismatch={upbMismatch} validationLoading={validationLoading} state={state}  />}
            {!isVerified ? (
              <div className="error_button_group">
                <Button
                  onClick={(e) => {
                    handleRegistrationFunction(e)
                  }}
                  text="Continue"
                  loading={validationLoading}
                  UID="sign_up"
                  bgColor="#475468"
                />
                <div role="alert" aria-live="polite">
                  <div
                    tabIndex="-1"
                    ref={errorRef}
                    className="error-message"
                    data-testid="verification-error-message"
                  >
                    {error}
                    <span
                      style={{ visibility: 'hidden' }}
                      dangerouslySetInnerHTML={{
                        __html: randomLetters,
                      }}
                    ></span>
                  </div>
                </div>
              </div>
            ) : (
              <>
              { (showupbAmountField && !isUpbVerified) ?
              null
              : (
                <>
                <hr />
                {getField('Email', true, 'email', false,'', true)}
                {getField('Confirm Email', true, 'confirm_email', false,'', true)}
                <div className="Form__formField___38Ikl">
                  <label className='sign-up-label pt-3' htmlFor="password">Password</label>
                  <PasswordInput
                    autoComplete="off"
                    placeholder="Password"
                    name="password"
                    datatestid="password_signup"
                    className="Input__input___3e_bf signup_input"
                    aria-required="true"
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                  />
                  <ReactIsCapsLockActive>
                    {(active) =>
                      active ? (
                        <p>
                          <span className="caps-lock-text">
                            CAPS LOC is active
                          </span>
                        </p>
                      ) : (
                        ''
                      )
                    }
                  </ReactIsCapsLockActive>
                  <label className='sign-up-label' for='confirmPassword'>
                    Confirm Password
                  </label>
                  <PasswordInput
                    autoComplete="off"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    datatestid="confirm_password_signup"
                    className="Input__input___3e_bf signup_input"
                    aria-required="true"
                    onChange={(e) =>
                      setConfirmPassword(e.target.value)
                    }
                    onPaste={(e) => e.preventDefault()}
                  />
                  <p role="alert">
                    <span className="error-message">{error}</span>
                  </p>
                  <PasswordValidation
                    password={password}
                    confirmPassword={confirmPassword}
                    showConfirmPassword={true}
                    setIsPasswordValid={(result) => {
                      setIsPasswordValid(
                        result && password === confirmPassword,
                      )
                    }}
                  />
                </div>
                <div className="error_button_group">
                  {loading ? (
                    <Button
                      disabled
                      loading
                      text="Loading..."
                      UID="sign_up"
                    />
                  ) : (
                    <RegistrationButton
                      disabled={!isPasswordValid || !isEmailValid || !(state.email && state.confirm_email)}
                      type="submit"
                    >
                      Continue
                    </RegistrationButton>
                  )}
                </div>
                </>)
              }
              </>
            )}
          </div>
        </form>
      </StyledSignUp>
    </>
  )
}

export default SignUp
