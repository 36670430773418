import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import AuthContainer from './AuthContainer'
import Title from '../../Common/Title'
import { getRandomLetters } from '../../services/auth'
import Button from '../../Common/button'
import { buildTestID, setCookie } from '../../Utils/utils'
import { trackRegistration } from '../../Global/Analytics'
import Countdown from 'react-countdown'
import MfaErrorModal from '../../Common/MfaErrorModal'
import CheckIcon from '../../assets/check-circle-icon'
import SuccessVerificationIcon from '../../assets/successful-verification-icon'
import { RegistrationButton } from './SignUp'

const StyledConfirmSignUpModern = Styled.div`
  width: 700px;
  justify-content: center;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 20px;
  @media (max-width:  ${({ theme }) => theme.breakPoints.medium}) and (min-width:  ${({ theme }) => theme.breakPoints.xSmall}) {
    height:60vh;
    };
  margin:40px auto;

.label {
  color: var(--Black, #000);
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;


  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
}

.verify_heading{
  font-family: Lato;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  color: #475468;

}
.verify_message{
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin-top:5px;
  word-wrap: break-word;
}
.input {
  font-family: Lato, sans-serif;
  border-radius: 10px;
  border: 1px solid #a9a9a9;
  background-color: var(--White, #fff);
  justify-content: center;
  color: var(--Grey-2, #777);
  font-weight: 400;
  line-height: 137.5%;
  width: 60%;
  
  display: flex;
  padding: 10px 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    width:100%;
    }
}

  .section-header {
    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .what_to_do_text {

  font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors.black87};
    margin: 0;
    margin-right: 8px;
  }

  .success-box{
    background:white;
    box-shadow:0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    padding:40px;
    display:flex;
    flex-direction:column;
    align-items:center;
    row-gap:10px;
    border-radius:10px;
    font-family: Lato;
    font-size: 26px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    margin-top:4rem;
    margin-bottom:8rem;
    .success-box-button{
      margin-top:1.8rem;
    }
  }
  .resend_code_text {
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
    color: darkgreen;
    margin: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakPoints.small}) {
  .success-box{
     .success-box-button{
      width:6rem;
    }
  }
  }
  @media (max-width: ${({ theme }) => theme.breakPoints.xSmall}) {
    width:100%;
    .success-box{
      height:22rem;
      .success-box-button{
        margin-top:5rem;
      }
    }
  };
  .resend-button{
    button{
      background:#ffffff !important;
    }
  }
`

const ConfirmSignUp = ({ setAuthState, authData }) => {
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [randomLetters, setRandomLetters] = useState('')
  const [codeSentVisible, setCodeSentVisible] = useState(false)
  const [canResendCode, setCanResendCode] = useState(false)
  const [showMfaErrorModal, setShowMfaErrorModal] = useState(false)
  const [isAccountVerified,setIsAccountVerified] = useState(false)
  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(4))
    setError(error)
  }

  const setCodeSent = () => {
    setCanResendCode(false)
    setCodeSentVisible(true)
    setTimeout(() => setCodeSentVisible(false), 5000)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await Auth.confirmSignUp(authData.username, code).then(() => {
        setCookie('confirm_sign_up', 'true', 1)
        trackRegistration.Completed()
        setIsAccountVerified(true)
      })
    } catch (err) {
      console.warn('err', err)
      setShowMfaErrorModal(true)
      setIsAccountVerified(false)
      setMessage('')
      setErrorForAda(err.message)
    }
  }

  return (
    <>
      <Title>Email Verification</Title>
      <StyledConfirmSignUpModern>
          {!isAccountVerified ?<form onSubmit={handleSubmit} style={{background:'white',boxShadow:'0px 1px 4px 0px rgba(0, 0, 0, 0.25)',padding:'25px',borderRadius:'20px'}}>
            <div className="Section__sectionBody___ihqqd">
              <div className="Form__formField___38Ikl">
                <div className="verify_heading">
                  Verify your email
                </div>
                <p className="verify_message">
                We've just sent the verification code to your email: <b>{authData.username}</b> &nbsp;
                Please, copy and paste this code here:
                </p>
              </div>
            </div>
            <div className="Section__sectionBody___ihqqd">
              <div className="Form__formField___38Ikl">
                <div className="label">
                  <label htmlFor="code">
                    <strong>Verification Code</strong>
                  </label>
                </div>
              <div style={{display:'flex',gap:'10px',alignItems:'center',flexWrap:'wrap'}}>
                <input
                  placeholder="Code"
                  name="code"
                  autoComplete="off"
                  data-testid={buildTestID(
                    'code',
                    '/home',
                    'confirm_sign_up',
                  )}
                  className="input"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  id="code"
                  aria-required="true"
                  type="text"
                  pattern="\d*"
                /> 
                <div class="resend-button">
                  <>
                    <Button
                      onClick={() => {
                      Auth.resendSignUp(authData.username).then(
                        () => {
                        setCodeSent()
                        },
                        )
                      // setCodeSent()
                      }}
                      text="Re-Send Code"
                      textButton
                      UID="enter_verify_code"
                      disabled={!codeSentVisible && !canResendCode}
                    />
                  </>
                </div>
                    {codeSentVisible && <>
                      <p className="error_text resend_code_text" role="alert">
                        <CheckIcon /> Sent
                      </p>
                    </>}
                </div>
              </div>
            </div>
            {!codeSentVisible && !canResendCode && <>
                <div className='verify_message'>Didn't receive code? Wait &nbsp;
                <Countdown
                  date={Date.now() + 20000}
                  onComplete={() => setCanResendCode(true)}
                  intervalDelay={1000}
                  precision={2}
                  renderer={(props) => <span>{props.seconds}</span>}
                />{' '}
                &nbsp;Seconds
                </div>
            </>}             
            <p role="alert">
              <span className="error-message">
                {error}
                <span
                  style={{ visibility: 'hidden' }}
                  dangerouslySetInnerHTML={{ __html: randomLetters }}
                ></span>
              </span>
            </p>
            <span className="text-success">{message}</span>
            <div className="buttons">
              <RegistrationButton type="submit" disabled={!code} text="Confirm" UID="confirm_sign_up">
                Verify Email
              </RegistrationButton>
            </div>
          </form>:
          <div className='success-box'>
            <SuccessVerificationIcon />
            Your account has been successfully verified.
            <RegistrationButton
              UID="confirm_sign_up"
              className='success-box-button'
              onClick={()=>{
                setAuthState('signIn')
                setIsAccountVerified(false)
              }}>
                Ok
            </RegistrationButton>
          </div>
          }
          <MfaErrorModal onHide={() => { setShowMfaErrorModal(false) }} show={showMfaErrorModal} />
      </StyledConfirmSignUpModern>
    </>
    )
}

export default ConfirmSignUp