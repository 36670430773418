import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import Header from '../Components/Header'
import Footer from '../Components/Footer'
import Theme from './Theme'
import LeavingSiteModal from '../Common/LeavingSiteModal'
import HeaderFinishYourAccount from '../Components/HeaderFinishYourAccount'
import FooterModern from '../Components/FooterModern'
import useWindowSize from '../Hooks/useWindowSize'
const StyledLayout = styled.div`
  padding-top: ${({ isAuthenticated }) =>
    isAuthenticated ? '140px' : '100px'};
  background: ${({ theme, greyBackground, modernBackground,imageBackground }) =>
    !imageBackground && ( greyBackground && !modernBackground ? theme.colors.background : modernBackground ? 'url(./assets/background.png), lightgray 50% / cover no-repeat' : theme.colors.white)};

  background-size: ${({ theme, greyBackground, modernBackground,imageBackground }) =>!imageBackground && (modernBackground ? '1920px 1441px' : 'auto')};
   @media (max-width: ${({ theme }) => theme.breakPoints.small}) {
     margin-top: ${({ imageBackground }) => !imageBackground && '80px'};
     padding-top: ${({ imageBackground }) => !imageBackground && '0px'};;
    }

    position: relative;

  .background-image{
    background-image:url(./assets/background-modern.jpeg);
    position:absolute;
    height:30rem;;
    z-index:-4;
    width:100%;
    top:0;
    height:${props=>props.getHeight()};
    border-bottom-right-radius: ${props=>props.isCircularBgImg ? '80% 25%':'100% 10%'};
    border-bottom-left-radius: ${props=>props.isCircularBgImg ? '80% 25%':'100% 10%'};
    background-size:100% 100%;
  }
  
  @media (max-width: ${({ theme }) => theme.breakPoints.medium}) {
    .background-image{
      height: ${({imageBackground,getHeight }) => imageBackground && getHeight()
    }
    }

  @media (max-width: ${({ theme, modernBackground }) => theme.breakPoints.xSmall}) {
    background: ${({ theme, modernBackground,imageBackground }) => !imageBackground && (modernBackground ? 'url(./assets/background.png), lightgray 50% / cover no-repeat' : theme.colors.white)};
    //  margin-top: ${({ theme, modernBackground,imageBackground }) =>!imageBackground && (modernBackground ? '105px' : '85px')};
    background-repeat: ${({ theme, modernBackground }) => modernBackground ? 'repeat-y' : 'inherit'};
    
    .background-image{
      height: ${({imageBackground,getHeight }) => imageBackground && getHeight() 
    }
    }

    }
`

const Layout = ({ children, isAuthenticated }) => {
  const [navVisible, setNavVisible] = useState(false)
  const [linkTo, setLinkTo] = useState('')
  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const { pathname } = useLocation()
  const imageBackgroundTabs = ['register'].some((tab)=>pathname.includes(tab))
  const {isXSmall,isMedium,isLarge} = useWindowSize()
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      isAuthenticated: isAuthenticated,
    })
  })

  const body = document.querySelector('body') // or const root = document.body

  const getHeight=()=>{
    const isLoanScreen=pathname.includes('my-loan')
    if (isLarge){
      return isLoanScreen?'95vh':'86vh'
    }
    else if (isXSmall){
      return isLoanScreen?'100vh':'100%'
    }
    else if (isMedium) {
      return isLoanScreen?'55vh':'60%'
    }
    else{
      return '100%'
    }
  }

  useEffect(() => {
    body.addEventListener('click', (e) => {
      if (e.target.href
        && !e.target.href.includes(window.location.hostname)
        && !e.target.href.includes('tel:')
        && !e.target.href.includes('mailto:')
        && !e.target.href.includes('cel-borrower')) {
        e.preventDefault();
        setLinkTo(e.target.href)
        setShowLeaveModal(true)
      }

      const selector = document.querySelector('h1.heading-6');
      if (e.target && (e.target?.classList?.contains('ScrollUpButton__Container') || e.target?.parentElement?.classList?.contains('ScrollUpButton__Container') || e.target?.parentElement?.parentElement?.classList?.contains('ScrollUpButton__Container'))) {
        e.target.blur()
        selector.focus()
      }

      return;
    })

    body.addEventListener('keyup', (e) => {
      // if key is enter key
      if (e.key === 'Enter') {
        const selector = document.querySelector('h1.heading-6');
        if (e.target && e.target?.classList?.contains('ScrollUpButton__Container')) {
          e.target.blur()
          selector.focus()
        }
      }
    })

  }, [body]);

  return (
    <Theme>
      <StyledLayout
        greyBackground={
          pathname.includes('my-loan') ||
          pathname.includes('transactions')
        }
        modernBackground={ pathname.includes('finish-your-account')}
        imageBackground={imageBackgroundTabs}
        isAuthenticated={isAuthenticated}
        onTouchMove={() => navVisible && setNavVisible(false)}
        onClick={() => navVisible && setNavVisible(false)}
        getHeight={getHeight}
        isCircularBgImg={isLarge}
      >
        {imageBackgroundTabs && <div className='background-image'></div>}
        {pathname !== '/finish-your-account' ?
          (
            <Header
              isAuthenticated={isAuthenticated}
              setNavVisible={setNavVisible}
              navVisible={navVisible}
            />
          ) : (
            <HeaderFinishYourAccount
              isAuthenticated={isAuthenticated}
              setNavVisible={setNavVisible}
              navVisible={navVisible}
            />)
        }
        {childrenWithProps}
        {pathname !== '/finish-your-account' &&
          <FooterModern />
        }
        {
          linkTo.length ? (<LeavingSiteModal onHide={() => { setShowLeaveModal(false) }} show={showLeaveModal} linkTo={linkTo} />) : ("")
        }
      </StyledLayout>
    </Theme>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
}

export default Layout